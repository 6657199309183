import {lazy} from "react";

export const routes = [
    {
        key: "home",
        path: "/home",
        index: false,
        component: lazy(() => import('../pages/Home')),
        authRequired: false
    },
    {
        key: "catering",
        path: "/catering/:caterId/:caterName",
        index: false,
        component: lazy(() => import('../pages/CateringProfile')),
        authRequired: false
    },
    {
        key: "checkout",
        path: "/checkout",
        index: false,
        component: lazy(() => import("../pages/Order/Checkout/Payment")),
        authRequired: false
    },
    {
        key: "error",
        path: "/error",
        index: false,
        component: lazy(() => import("../pages/Error")),
        authRequired: false
    },
    {
        key: "order",
        path: "/order/:orderId",
        index: false,
        component: lazy(() => import("../pages/Order/OrderInformation")),
        authRequired: false
    },
    {
        key: "my-orders",
        path: "/my-orders",
        index: false,
        component: lazy(() => import("../pages/MyOrders")),
        authRequired: true
    },
    {
        key: "favorite-restaurants",
        path: "/favorite-restaurants",
        index: false,
        component: lazy(() => import("../pages/Favorite")),
        authRequired: true
    },
    {
        key: "profile",
        path: "/profile",
        index: false,
        component: lazy(() => import("../pages/Profile")),
        authRequired: true
    },
    {
        key: "recovery-password",
        path: "/recovery-password/:token",
        index: false,
        component: lazy(() => import("../pages/Auth/RecoveryPassword")),
        authRequired: false
    },
    {
        key: "verify-mail",
        path: "/verify-mail/:token",
        index: false,
        component: lazy(() => import("../pages/Auth/VerifyMail")),
        authRequired: false
    },
    {
        key: "campaigns",
        path: "/campaigns/:slug",
        index: false,
        component: lazy(() => import("../pages/Campaigns")),
        authRequired: false
    },
    {
        key: "corporate",
        path: "/corporate",
        index: false,
        component: lazy(() => import("../pages/Corporate")),
        authRequired: true
    },
    {
        key: "employees",
        path: "/employees",
        index: false,
        component: lazy(() => import("../pages/Employees")),
        authRequired: true
    },
    {
        key: "invoices",
        path: "/invoices",
        index: false,
        component: lazy(() => import("../pages/Invoices")),
        authRequired: true
    },
    {
        key: "invoicesDetail",
        path: "/invoices/:invoiceId",
        index: false,
        component: lazy(() => import("../pages/Invoices/InvoiceDetail")),
        authRequired: true
    },
    {
        key: "employeeOrder",
        path: "/employee-order/:id/:name",
        index: false,
        component: lazy(() => import("../pages/Employees/OrderList")),
        authRequired: true
    },
    {
        key: "corporateCateringProfile",
        path: "/catering-list/:orderDate",
        index: false,
        component: lazy(() => import("../pages/CateringProfile/CorporateCateringProfile")),
        authRequired: true
    },
    {
        key: "corporateCateringProductList",
        path: "/product-list/:productGroupId",
        index: false,
        component: lazy(() => import("../pages/CateringProfile/CorporateCateringProfile/CorporateProductList")),
        authRequired: false
    },
    {
        key: "corporateOrderSummary",
        path: "/order-summary/:orderDate",
        index: false,
        component: lazy(() => import("../pages/Corporate/CorporateOrderSummary")),
        authRequired: true
    },
    {
        key: "productContent",
        path: "/product-content/:productId",
        index: false,
        component: lazy(() => import("../pages/ProductContent")),
        authRequired: false
    },
    {
        key: "kaiserReviewOrder",
        path: "/kaiser-review-order/:orderId",
        index: false,
        component: lazy(() => import("../pages/Kaiser/KaiserOrderReview")),
        authRequired: false
    }
]
