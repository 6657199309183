import {forwardRef, Fragment, useEffect} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import Footer from "../footer";
import {Outlet} from "react-router-dom";
import CaterButton from "../../components/CaterButton";
import {setDraftOrder, setDraftOrderList, setItems, setShowCartPanel} from "../../pages/Order/Cart/store";
import {useDispatch, useSelector} from "react-redux";
import {IoCartOutline, IoLocationOutline, IoTimeOutline} from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
import Address from "../../pages/Modals/Address";
import {setAddress, setDeliveryTime, setModal} from "../../pages/Modals/Address/store";
import {USDollar} from "../../utils/moneyFormat";
import DeliveryTimePicker from "../../components/DeliveryTimePicker";
import {MobileNav} from "./MobileNav";
import {logout, setActiveTab, setAuthModal, setUpdateUser} from "../../pages/Auth/store";
import Auth from "../../pages/Auth";
import md5 from "md5";
import ConfirmAlert from "./ConfirmAlert";
import {apiCheckUser} from "../../service/AuthService";
import {showToast} from "../../utils/showToast";
import {apiGetDraftOrder, apiGetDraftOrderGuest, apiSaveDraft, apiSaveDraftGuest} from "../../service/OrderService";
import moment from "moment/moment";
import cloneDeep from "lodash/cloneDeep";
import {getListEmployee} from "../../pages/Employees/store";
import HomePageEmployeeList from "../../pages/Employees/HomePageEmployeeList";
import CorporateCart from "../../pages/Corporate/CorporateCart";
import {listCorporateOrder, listCorporateSummaryOrder, setCurrentUser} from "../../pages/Corporate/store";
import ScrollToTopButton from "../../pages/CateringProfile/CorporateCateringProfile/ScrollToTopButton";
import {setBasketItem} from "../../pages/CateringProfile/CaterFoodDetails/store";
import {kaiserHostCheck} from "../../utils/kaiserHostCheck";

const navigation = [
    {name: 'Store List', href: '/home', loginRequired: false, current: true, corporate: false, corporateHidden: false},
    {
        name: 'My Orders',
        href: '/my-orders',
        loginRequired: true,
        current: false,
        corporate: false,
        corporateHidden: false
    },
    {
        name: 'Favorite Restaurants',
        href: '/favorite-restaurants',
        loginRequired: true,
        current: false,
        corporate: false,
        corporateHidden: true
    }
]

const userNavigation = [
    {name: 'Your Profile', href: '/profile'},
    {name: 'Sign out', href: '#'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Header() {
    const dispatch = useDispatch();
    const addressState = useSelector((state) => state.address);
    const authState = useSelector((state) => state.auth);
    const corporateState = useSelector((state) => state.corporate);
    const draftOrderState = useSelector((state) => state.draftOrder);

    const DatePickerButton = forwardRef(({value, onClick}, ref) => (
        <CaterButton icon={<IoTimeOutline/>}
                     onClick={onClick}
                     className={"!border-none !ring-0 !shadow-none !hover:bg-gray-400 "}>
            <div className={"flex gap-2"}>
                <p className={"text-gray-500"}>Delivery
                    Time</p> • <p>{addressState.deliveryTime === "Now" ? "Now" : new Date(addressState.deliveryTime).toLocaleString()}</p>
            </div>
        </CaterButton>
    ))

    const checkVerify = async () => {
        try {
            const response = await apiCheckUser();

            if (response.data.result) {
                dispatch(setUpdateUser({
                    id: authState.data?.user.id,
                    mail: response.data.data.mail,
                    fullName: response.data.data.fullName,
                    type: response.data.data.type,
                    phone: response.data.data.phone,
                    verify: response.data.data.verify,
                    isCorporate: response.data.data.isCorporate,
                    isCorporateAdmin: response.data.data.isCorporate,
                    corporate: response.data.data?.corporate
                }))
            } else {
                showToast(response.data.userMessage, "Warning", "warning")
            }
        } catch (e) {
            showToast(e.message, "Warning", "warning")
        }
    }



    useEffect(() => {
        if (authState.data?.user?.isCorporate) {
            dispatch(setAddress({
                title: "",
                address: authState.data?.user?.corporate?.address,
                city: authState.data?.user?.corporate?.city,
                state: authState.data?.user?.corporate?.state,
                postalCode: authState.data?.user?.corporate?.postalCode
            }));
            dispatch(setModal(false));

            if (authState.data?.user?.corporate?.orderType === "ADMIN_USER") {
                dispatch(listCorporateSummaryOrder())
            }
        }
    }, [authState.data?.user?.isCorporate]);

    useEffect(() => {
        if (authState.logged) {
            checkVerify();
        }


        if (!addressState.data.address && !authState.data?.user?.isCorporate) {
            if (!window.location.href.includes("product-list") && !window.location.href.includes("product-content")) {
                dispatch(setAddress({
                    title: "",
                    address: "303 S Almaden Blvd 16 Ste",
                    city: "San Jose",
                    state: "California",
                    postalCode: "95110"
                }));
            }

        } else {
            if (!authState.data?.user?.isCorporate) {
                const clientSecret = new URLSearchParams(window.location.search).get(
                    "payment_intent_client_secret"
                );

                if (!clientSecret) {
                    if (new Date(addressState.deliveryTime) <= new Date()) {
                        dispatch(setDeliveryTime(moment(new Date()).add(1, 'days').hour(10).minute(0).second(0).toLocaleString()))
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (authState.logged && authState.data?.user?.isCorporate) {
            if (corporateState.weekly.length > 0) {
                if (addressState.deliveryTime === "Now" || !addressState.deliveryTime || moment().format('YYYY-MM-DD') > moment(addressState.deliveryTime, 'YYYY-MM-DD')) {
                    const date = corporateState.weekly[0].weekDate ? corporateState.weekly[0].weekDate : new Date();
                    const time = authState.data?.user?.corporate.deliveryTime;
                    dispatch(setDeliveryTime(moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')));
                }
            }
        }
    }, [corporateState.weekly]);

    const updateTimeForDraft = async () => {
        if (addressState.deliveryTime) {
            const cloneDraftOrder = cloneDeep(draftOrderState.draftOrder);
            cloneDraftOrder.deliveryTime = addressState.deliveryTime === "Now" ? new Date().toISOString() : new Date(addressState.deliveryTime).toISOString();
            cloneDraftOrder.deliveryTimeType = addressState.deliveryTime === "Now" ? "Now" : "scheduled";
            cloneDraftOrder.type = addressState.deliveryTime === "Now" ? "on-demand" : "scheduled";
            dispatch(setDraftOrder(cloneDraftOrder));
            // authState.logged ? await apiSaveDraft(cloneDraftOrder) : await apiSaveDraftGuest(cloneDraftOrder);
        }
    };

    useEffect(() => {
        if (!authState.data?.user?.isCorporate) {
            updateTimeForDraft();
        }

    }, [addressState.deliveryTime]);


    return (
        <>
            <div className="flex flex-col min-h-screen">

                {authState.logged && !authState.data?.user?.verify && (
                    <ConfirmAlert/>
                )}


                {/*<TestAlert/>*/}
                <div className={"flex-1"}>
                    <Disclosure as="nav" className="bg-gray-800">
                        {({open}) => (
                            <>
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <div className="flex h-16 items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="w-40"
                                                    src="/logo-white.svg"
                                                    alt="CaterValley Logo"
                                                />
                                            </div>

                                            <div className="hidden md:block">
                                                <div className="ml-10 flex items-baseline space-x-4">
                                                    {navigation.map((item) => (
                                                        <>
                                                            {(authState.data?.user?.isCorporate && item.corporateHidden) ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <a
                                                                    href={item.href}
                                                                    key={item.name}
                                                                    hidden={(item.corporate ? ((!(authState.logged && authState.data?.user?.isCorporate))) : authState.logged ? false : item.loginRequired)}
                                                                    className={classNames(
                                                                        window.location.pathname === item.href
                                                                            ? 'bg-gray-900 text-white'
                                                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                                    )}
                                                                    aria-current={item.current ? 'page' : undefined}
                                                                >
                                                                    {authState.data?.user?.isCorporate && item.name === "Store List" ? "My Orders" : item.name === "My Orders" ? "Previous Orders" : item.name}
                                                                </a>
                                                            )}

                                                        </>
                                                    ))}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="hidden md:block">
                                            <div className="ml-4 flex items-center md:ml-6">

                                                {authState.logged && (
                                                    <>
                                                        <Menu as="div" className="relative ml-3">
                                                            <div>
                                                                <Menu.Button
                                                                    className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm  ">
                                                                    <span className="absolute -inset-1.5"/>
                                                                    <span className="sr-only">Open user menu</span>
                                                                    <img className="h-8 w-8 rounded-full"
                                                                         src={authState.logged ? "https://gravatar.com/avatar/" + md5(authState.data.user.mail) : ""}
                                                                         alt=""/>
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items
                                                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                                    <Menu.Item>
                                                                        {({active}) => (
                                                                            <a
                                                                                href={"/profile"}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100' : '',
                                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                                )}
                                                                            >
                                                                                Your Profile
                                                                            </a>
                                                                        )}
                                                                    </Menu.Item>

                                                                    {authState.data?.user?.isCorporateAdmin && (
                                                                        <>
                                                                            <Menu.Item>
                                                                                {({active}) => (
                                                                                    <a
                                                                                        href={"/invoices"}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100' : '',
                                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                                        )}
                                                                                    >
                                                                                        Invoices
                                                                                    </a>
                                                                                )}
                                                                            </Menu.Item>


                                                                            <Menu.Item>
                                                                                {({active}) => (
                                                                                    <a
                                                                                        href={"/employees"}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100' : '',
                                                                                            'block px-4 py-2 text-sm text-gray-700'
                                                                                        )}
                                                                                    >
                                                                                        Employees
                                                                                    </a>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </>
                                                                    )}

                                                                    <Menu.Item>
                                                                        <a
                                                                            onClick={() => {
                                                                                dispatch(logout());
                                                                            }}
                                                                            href={""}
                                                                            className={classNames(
                                                                                'block px-4 py-2 text-sm text-gray-700'
                                                                            )}
                                                                        >
                                                                            Sign Out
                                                                        </a>
                                                                    </Menu.Item>


                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </>
                                                )}


                                                { !authState.logged && (
                                                    <div className={"flex gap-2"}>
                                                        <CaterButton
                                                            onClick={() => {
                                                                dispatch(setActiveTab("login"));
                                                                dispatch(setAuthModal(true));
                                                            }}
                                                            hidden={kaiserHostCheck()}
                                                        >
                                                            Sign In
                                                        </CaterButton>

                                                        <CaterButton
                                                            onClick={() => {
                                                                dispatch(setActiveTab("register"));
                                                                dispatch(setAuthModal(true));
                                                            }}
                                                            hidden={kaiserHostCheck()}
                                                        >
                                                            Sign Up
                                                        </CaterButton>
                                                    </div>
                                                )}

                                                {/* Profile dropdown */}

                                            </div>
                                        </div>

                                        {!authState.data?.user?.isCorporate && (
                                            <div className={"lg:hidden md:hidden"}>
                                                <CaterButton
                                                    hidden={kaiserHostCheck()}
                                                    icon={<IoCartOutline/>}
                                                    color={"indigo"}
                                                    className={"!border-none !ring-0 !shadow-none !hover:bg-gray-400 ml-auto"}
                                                    onClick={() => dispatch(setShowCartPanel(true))}
                                                >

                                                    Cart •

                                                    {authState.logged && authState.data?.user?.isCorporate ? (
                                                            <>
                                                                {USDollar.format(corporateState.currentOrder?.subTotal || 0)}
                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                {USDollar.format(draftOrderState.draftOrder?.draftOrderItems?.reduce((sum, item) => sum + item.itemTotalPrice, 0) || 0)}
                                                            </>
                                                        )}

                                                </CaterButton>
                                            </div>
                                        )}


                                    </div>
                                </div>


                            </>
                        )}
                    </Disclosure>

                    <header className="bg-white shadow">
                        <div className={"hidden lg:block md:block"}>
                            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex gap-4 items-center">
                                <div className={"flex items-center gap-2"}>


                                    {kaiserHostCheck() ? (
                                        <h1 className="text-2xl font-bold tracking-tight text-gray-900">Welcome Kaiser!</h1>
                                    ) : (
                                        <h1 className="text-2xl font-bold tracking-tight text-gray-900">Welcome {authState.logged ? authState.data?.user?.fullName : ""}</h1>
                                    )}

                                </div>
                                {!authState.data?.user?.isCorporate && (
                                    <>
                                        <CaterButton
                                            hidden={kaiserHostCheck()}
                                            icon={<IoLocationOutline/>}
                                            onClick={() => dispatch(setModal(true))}
                                            className={"!border-none !ring-0 !shadow-none hover:bg-gray-400"}
                                        >
                                            {addressState.data.title || addressState.data.address || "Select Address"}
                                        </CaterButton>


                                        {!kaiserHostCheck() && (
                                            <DeliveryTimePicker refButton={<DatePickerButton/>}/>
                                        )}



                                        <CaterButton
                                            hidden={kaiserHostCheck()}
                                            icon={<IoCartOutline/>}
                                            color={"indigo"}
                                            className={"!border-none !ring-0 !shadow-none !hover:bg-gray-400 ml-auto"}
                                            onClick={() => dispatch(setShowCartPanel(true))}
                                        >

                                            Cart
                                            •
                                            {authState.logged && authState.data?.user?.isCorporate ? (
                                                    <>
                                                        {USDollar.format(corporateState.currentOrder?.subTotal || 0)}
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        {USDollar.format(draftOrderState.draftOrder?.draftOrderItems?.reduce((sum, item) => sum + item.itemTotalPrice, 0) || 0)}
                                                    </>
                                                )}

                                        </CaterButton>
                                    </>
                                )}
                            </div>
                        </div>


                        {!authState.data?.user?.isCorporate && (
                            <div className={"lg:hidden md:hidden"}>
                                <div
                                    className="mx-auto max-w-7xl px-2 py-2 sm:px-6 lg:px-8 grid grid-rows-2 gap-2 justify-center items-center">

                                    <CaterButton
                                        icon={<IoLocationOutline/>}
                                        onClick={() => dispatch(setModal(true))}
                                        className={"!border-none !ring-0 !shadow-none hover:bg-gray-400 !text-start"}
                                    >
                                        {addressState.data.title || addressState.data.address || "Select Address"}
                                    </CaterButton>

                                    <DeliveryTimePicker refButton={<DatePickerButton/>}/>

                                </div>
                            </div>
                        )}


                    </header>
                    <main>

                        {authState.data?.user?.isCorporateAdmin && (
                            <div className="mx-auto max-w-full py-6 px-2 sm:px-6 lg:px-8">
                                <div className={"grid grid-cols-1 sm:grid-cols-[18%_64%_18%] gap-2"}>
                                    <div>
                                    </div>
                                    <div className={"mb-16"}>
                                        <Outlet/>
                                    </div>
                                    <div className={"w-full"}>
                                        <CorporateCart/>
                                    </div>
                                </div>
                            </div>
                        )}


                        {!authState.data?.user?.isCorporateAdmin && (
                            <div className="mx-auto max-w-7xl py-6 px-2 sm:px-6 lg:px-8">
                                <div className={"mb-16"}>
                                    <Outlet/>
                                </div>
                            </div>
                        )}

                        <div className={"z-40"}>
                            <ScrollToTopButton/>
                        </div>

                    </main>
                    <MobileNav/>
                </div>
                <Address/>
                <Auth/>
                <div className={"hidden md:block lg:block"}>
                    <Footer/>
                </div>
            </div>
        </>
    )


}
